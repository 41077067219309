import * as React from 'react';
import { ImageField, Edit, SimpleForm, required, TextInput, ImageInput } from 'react-admin';
import { WriteToolbar } from '../../Components/WriteToolBar';

const PreviewImage = ({ record, source }) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const MortageTitle = ({ record }) => {
  return <span>{record ? 'Edit Mortage' : ''}</span>;
};

export const MortageEdit = (props) => (
  <Edit title={<MortageTitle />} {...props}>
    <SimpleForm toolbar={<WriteToolbar />}>
      <TextInput disabled label="id" source="id" />
      <TextInput source="link" validate={required()} />
      <ImageInput source="logo" label="Logo">
        <PreviewImage source="logo" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
