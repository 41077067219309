import { fetchUtils } from 'react-admin';
import { FirebaseAuthProvider } from 'react-admin-firebase';
import { firebaseConfig as config } from '../firebaseConfig';
import { BASE_URL } from '../Constants/index';
import jsonplaceholder from 'ra-data-json-server';
export async function getUser() {
  return authProvider
    .checkAuth()
    .then((res) => {
      return res;
    })
    .catch((err) => console.log('error'));
}
const httpClient = async (url, options = {}) => {
  let token;
  token = JSON.parse(localStorage.getItem('token'));
  const currDateTime = new Date();
  if (currDateTime > token.expirationTime) {
    const user = await authProvider.getAuthUser();
    token = await user.getIdTokenResult();
    localStorage.setItem('token', JSON.stringify(token));
  }

  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      // id: 'HSUNCGPWD9HElToPJ7fz',
    });
  }

  // The most common usage of custom headers is for authentication.
  // fetchJson has built-on support for the Authorization token header:
  options.user = {
    authenticated: true,
    token: `Bearer ${token.token}`,
  };

  // add your own headers here
  // options.header.set('key', 'value')
  return fetchUtils.fetchJson(url, options);
};

const firebaseAuthProvider = FirebaseAuthProvider(config);
export const dataProvider = jsonplaceholder(BASE_URL, httpClient);
export const myDataProvider = {
  ...dataProvider,
  update: (resource, params) => {
    if (resource !== 'mortage' || !params.data.logo) {
      return dataProvider.update(resource, params);
    }

    return convertFileToBase64(params.data.logo.rawFile)
      .then((base64Pictures) => ({
        src: base64Pictures,
        title: `${params.data.title}`,
      }))
      .then((transformedNewPicture) =>
        dataProvider.update(resource, {
          ...params,
          data: {
            ...params.data,
            logo: transformedNewPicture.src,
          },
        })
      )
      .catch((error) => {
        Promise.reject(error);
      });
  },
  // create: (resource, params) => {
  //   console.log(resource, params);
  //   if (!resource.includes('adminAnnouncements')) {
  //     return dataProvider.create(resource, params);
  //   }
  //   console.log(resource, 'resource', params, 'params');
  //   const index = params.data.check.indexOf(`href=\"`);
  //   const p = params.data.check.substring(0, index + 5) + params.data.check.substring(index + 5);
  //   console.log(params.data.check, 'params.data.check');
  //   params.data = {
  //     ...params.data,
  //     check: p,
  //     test: String(p),
  //   };
  //   console.log(params.data, 'params.data');
  //   return httpClient(`${BASE_URL}/${resource}`, {
  //     method: 'POST',
  //     body: JSON.stringify(params.data),
  //   }).then(({ json }) => ({
  //     data: { ...params.data, id: json.id },
  //   }));
  // },
};
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export const authProvider = {
  ...firebaseAuthProvider,
  login: async ({ username, password }) => {
    await firebaseAuthProvider.login({ username, password });
    const user = await firebaseAuthProvider.getAuthUser();
    const token = await user.getIdTokenResult();
    localStorage.setItem('token', JSON.stringify(token));
  },
  logout: async () => {
    await firebaseAuthProvider.logout();
    localStorage.clear('token');
  },
};
