import * as React from 'react';
import {
  Datagrid,
  TextField,
  List,
  FileField,
  DateField,
} from 'react-admin';
export const DocumentHomesList = (props) => {
  return (
    <List {...props} sort={{ field: 'name', order: 'desc' }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="home.name" label="Home Name" sortable={false} />
        <TextField source="home.address" label="Home Address" sortable={false} />
        <TextField source="title" sortable={false} />
        <TextField source="description" sortable={false} />
        {/* <ReferenceField reference="divisions" source="divisionId">
          <TextField source="name" />
        </ReferenceField> */}
        {/* <ReferenceField reference="admins" source="uploadedById">
          <TextField source="firstName" />
        </ReferenceField> */}
        {/* <TextField source="divisionId" label="divisionId"/> */}
        <TextField source="uploadedBy" sortable={false} />
        <FileField
          source="fileLocation[0]"
          title="title"
          target="_blank"
          download={true}
          label="File Location"
          sortable={false}
        />
        <DateField source="uploadedAt" />
      </Datagrid>
    </List>
  );
};
