import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  TextField,
  required,
  ArrayInput,
  SimpleFormIterator,
  FileInput,
  FileField,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
  minLength,
  maxLength,
} from 'react-admin';

export const DocumentDivisionsCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextField source="Create Document" />
        <TextInput source="documentType" label="Document Type" defaultValue="divisions" disabled />

        {/* <ReferenceInput
          validate={[required()]}
          source="divisionId"
          reference="divisions"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput> */}
        <ArrayInput
          source="array"
          name="hello"
          validate={[
            required(),
            minLength(1, 'Enter atleast one division'),
            maxLength(5, 'Enter atmost 5 divisions'),
          ]}
        >
          <SimpleFormIterator>
            <FormDataConsumer>
              {({
                formData,
                scopedFormData, // The data for this item of the ArrayInput
                getSource,
                ...rest
              }) => {
                console.log(formData);
                return (
                  <>
                    <ReferenceInput
                      validate={[required()]}
                      source={getSource(`divisionId`)}
                      reference="divisions"
                      label="divisions"
                      sort={{ field: 'name', order: 'ASC' }}
                    >
                      <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="files">
          <SimpleFormIterator>
            <TextInput source="title" label="Title" validate={[required()]} />
            <TextInput source="description" label="Description" validate={[required()]} />
            {/* <TextInput source="location" label="Location" validate={[required()]} /> */}
            <FileInput
              source="location"
              options={{label:"Location"}}
              minSize={5}
              maxSize={10000000}
              validate={[required()]}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
