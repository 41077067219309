import {
  Avatar,
  Typography,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-admin';
import { BASE_URL } from '../../Constants';
import { getUser } from '../../Providers/index';
export default function PopUp(props) {
  const { openPopUp, setOpenPopup, record } = props;
  const [data, setData] = useState(null);
  // const token = localStorage.getItem('token');
  const [error, setError] = useState('');
  useEffect(() => {
    setData('');
    setError('');

    const fetchData = async () => {
      if (record) {
        const res = await getUser();
        const tokenId = res.Aa;
        const token = tokenId;
        const options = {
          headers: new Headers({
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            // id: `jUmq2uba4EoiUX0Ge9AF`,
          }),
        };
        const data = await fetch(`${BASE_URL}/homeHomebuyers?homeId=${record.id}`, options);
        if (data.status === 200) {
          const sample = await data.json();
          if (sample.length < 1) setError('No records found');
          setData(sample);
        } else {
          setError('Internal server error');
        }
      }
    };
    fetchData();
    // await admin.firestore().collection();
  }, [openPopUp, record]);

  return (
    <Dialog open={openPopUp}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        Home Buyers
      </DialogTitle>
      <DialogContent>
        <List>
          {data
            ? data.map((homebuyer) => (
                <React.Fragment key={homebuyer.id}>
                  <ListItem style={{ display: 'block' }}>
                    <Typography component="div" style={{ margin: '2px 0' }}>
                      <Typography component="span" style={{ fontWeight: 600 }}>
                        FirstName :
                      </Typography>
                      {homebuyer.firstName}
                    </Typography>
                    <Typography component="div" style={{ margin: '2px 0' }}>
                      <Typography component="span" style={{ fontWeight: 600 }}>
                        LastName :
                      </Typography>
                      {homebuyer.lastName}
                    </Typography>
                    <Typography component="div" style={{ margin: '2px 0' }}>
                      <Typography component="span" style={{ fontWeight: 600 }}>
                        Email :
                      </Typography>
                      {homebuyer.email}
                    </Typography>
                    <Typography component="div" style={{ margin: '2px 0' }}>
                      <Typography component="span" style={{ fontWeight: 600 }}>
                        PhoneNumber :
                      </Typography>
                      {homebuyer.phoneNumber}
                    </Typography>
                    <Typography component="div" style={{ margin: '2px 0' }}>
                      <Typography component="span" style={{ fontWeight: 600 }}>
                        Type :
                      </Typography>
                      {homebuyer.type}
                    </Typography>
                    <Typography component="div" style={{ margin: '2px 0' }}>
                      <Typography component="span" style={{ fontWeight: 600 }}>
                        Photo :
                      </Typography>
                      {/* <img src={homebuyer.photo} /> */}
                      <Avatar
                        variant="rounded"
                        style={{ display: 'inline-block', verticalAlign: 'middle' }}
                        alt=""
                        src={homebuyer.photo}
                      />
                    </Typography>
                    <Divider component="div" style={{ marginTop: '10px' }} />
                  </ListItem>
                </React.Fragment>
              ))
            : null}
          {error !== '' ? <div>error</div> : null}
        </List>
      </DialogContent>
      <DialogActions style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <Button
          label="close"
          onClick={() => {
            setOpenPopup(false);
          }}
        ></Button>
      </DialogActions>
    </Dialog>
  );
}
export function ButtonField(props) {
  const { record, setRecord, setOpenPopup } = props;
  return (
    <Button
      label="show buyers"
      onClick={() => {
        setRecord(record);
        setOpenPopup(true);
      }}
    />
  );
}
