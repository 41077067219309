import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  TextField,
  required,
  ArrayInput,
  SimpleFormIterator,
  FileInput,
  FileField,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
} from 'react-admin';

import { BASE_URL } from '../../Constants';
import { getUser } from '../../Providers/index';

export const DocumentHomesCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextField source="Create Document" />
        <TextInput source="documentType" label="Document Type" defaultValue="homes" disabled />
        <ArrayInput source="array">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({
                formData,
                scopedFormData, // The data for this item of the ArrayInput
                getSource,
                ...rest
              }) => {
                console.log(formData);
                return (
                  <>
                    <ReferenceInput
                      validate={[required()]}
                      source={getSource(`divisionId`)}
                      reference="divisions"
                      label="Division"
                      sort={{ field: 'name', order: 'ASC' }}
                    >
                      <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                    {scopedFormData
                      ? scopedFormData.divisionId && (
                          <CustomCommunitiesInput
                            formData={formData}
                            scopedFormData={scopedFormData}
                            getSource={getSource}
                          />
                        )
                      : null}
                    {scopedFormData
                      ? scopedFormData.communityId && (
                          <CustomHomesInput
                            formData={formData}
                            scopedFormData={scopedFormData}
                            getSource={getSource}
                          />
                        )
                      : null}
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="files" validate={[required()]}>
          <SimpleFormIterator>
            <TextInput source="title" label="Title" validate={[required()]} />
            <TextInput source="description" label="Description" validate={[required()]} />
            <FileInput
              source="location"
              labelSingle="Drop File"
              minSize={5}
              maxSize={10000000}
              validate={[required()]}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

const CustomCommunitiesInput = (customProps) => {
  const [choices, setChoices] = React.useState([]);
  const [inputValue] = React.useState('');
  const [initialValue, setInitialValue] = React.useState('');
  const { scopedFormData, getSource } = customProps;
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    return () => {
      setInitialValue('');
      setChoices([]);
    };
  }, [scopedFormData.divisionId]);
  React.useEffect(() => {
    const check = async () => {
      const res = await getUser();
      const tokenId = res.Aa;
      const token = tokenId;
      const options = {
        headers: new Headers({
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        }),
      };
      const data = await fetch(
        `${BASE_URL}/divisionCommunities?divisionId=${scopedFormData.divisionId}&q=${inputValue}`,
        options
      );
      if (data.status === 200) {
        const choices = await data.json();
        if (choices.length === 0) {
          setInitialValue('No records');
        } else {
          setChoices(choices);
        }
      }
      setLoading(false);
    };
    check();
    return () => {
      setLoading(true);
      setInitialValue('');
      setChoices([]);
    };
  }, [scopedFormData.divisionId, inputValue]);
  return (
    <>
      <AutocompleteInput
        validate={[required()]}
        source={getSource(`communityId`)}
        choices={choices}
        options={{ label: 'Community' }}
        // onInputValueChange={(inputValue) => {
        //   console.log(inputValue);
        //   setInputValue(inputValue);
        // }}
        loading={loading}
        inputValue={initialValue}
      />
    </>
  );
};

const CustomHomesInput = (customProps) => {
  const [choices, setChoices] = React.useState([]);
  const { scopedFormData, getSource } = customProps;
  const [inputValue] = React.useState('');
  const [initialValue, setInitialValue] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    return () => {
      setInitialValue('');
      setChoices([]);
    };
  }, [scopedFormData.divisionId, scopedFormData.communityId]);

  React.useEffect(() => {
    const check = async () => {
      const res = await getUser();
      const tokenId = res.Aa;
      const token = tokenId;
      const options = {
        headers: new Headers({
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        }),
      };
      const data = await fetch(
        `${BASE_URL}/communityHomes?communityId=${scopedFormData.communityId}&q=${inputValue}`,
        options
      );
      if (data.status === 200) {
        const choices = await data.json();
        if (choices.length === 0) {
          setInitialValue('No records');
        } else {
          setChoices(choices);
        }
      }
      setLoading(false);
    };
    check();
    return () => {
      setLoading(true);
      setInitialValue('');
      setChoices([]);
    };
  }, [scopedFormData.communityId, inputValue]);
  console.log(initialValue, 'initialValue');
  console.log(loading, 'loading');
  return (
    <>
      <AutocompleteInput
        validate={[required()]}
        source={getSource(`homeId`)}
        choices={choices}
        options={{ label: 'Home' }}
        // onInputValueChange={(inputValue) => {
        //   console.log(inputValue);
        //   setInputValue(inputValue);
        // }}
        disabled={choices.length === 0}
        loading={loading}
        optionText={(record) => (record ? record.name + record.address : '')}
        inputValue={initialValue}
        helperText={initialValue}
      />
    </>
  );
};
