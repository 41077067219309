import * as React from 'react';
import { Admin,  Resource } from 'react-admin';
import CustomLoginPage from './Pages/Login/CustomLoginPage';
import { authProvider, myDataProvider } from './Providers/index';
import settings from './Pages/Settings/index';
import mortage from './Pages/Mortages/index';
import { conversations } from './Pages/Conversations/index';
import { communitiesList } from './Pages/Communities/communities';
import { HomeBuyersList } from './Pages/Homebuyers/homebuyers';
import { HomesList } from './Pages/Homes/homes';
import { CustomMenu } from './Menu/Menu';
import { DivisionList } from './Pages/Divisions/divisions';
import { DocumentDivisionsList } from './Pages/Documents/documentDivisionsList';
import { DocumentCommunitiesList } from './Pages/Documents/documentCommunitiesList';
import { DocumentHomesList } from './Pages/Documents/documentHomesList';
import { DocumentDivisionsCreate } from './Pages/Documents/documentDivisionsCreate';
import { DocumentCommunitiesCreate } from './Pages/Documents/documentCommunitiesCreate';
import { DocumentHomesCreate } from './Pages/Documents/documentHomesCreate';
import { AdminCreate } from './Pages/Admins/AdminCreate.jsx';
import { AdminEdit } from './Pages/Admins/AdminEdit.jsx';
import { AdminsList } from './Pages/Admins/AdminList.jsx';

const App = () => {
  return (
    <Admin
      dataProvider={myDataProvider}
      authProvider={authProvider}
      loginPage={CustomLoginPage}
      menu={CustomMenu}
    >
      {(permissions) => [
        // HOME BUYERS
        permissions.roles.includes('corp') ||
        permissions.roles.includes('rep') ||
        permissions.roles.includes('office') ? (
          <Resource name="homebuyers" options={{ label: 'Home Buyers' }} list={HomeBuyersList} />
        ) : null,

        // HOMES
        permissions.roles.includes('corp') ||
        permissions.roles.includes('rep') ||
        permissions.roles.includes('office') ? (
          <Resource name="homes" options={{ label: 'Homes' }} list={HomesList} />
        ) : null,

        // ADMINS
        permissions.roles.includes('corp') ||
        permissions.roles.includes('super') ||
        permissions.roles.includes('office') ? (
          <Resource
            name="admins"
            options={{ label: 'Admins' }}
            list={AdminsList}
            edit={permissions.roles.includes('super') ? AdminEdit : null}
            create={permissions.roles.includes('super') ? AdminCreate : null}
          />
        ) : null,

        // DIVISIONS
        permissions.roles.includes('corp') ||
        permissions.roles.includes('office') ||
        permissions.roles.includes('super') ? (
          <Resource name="divisions" options={{ label: 'Divisions' }} list={DivisionList} />
        ) : null,

        // COMMUNITIES
        // permissions.roles.includes('corp') ||
        // permissions.roles.includes('rep') ||
        // permissions.roles.includes('super')  ? (
        <Resource name="communities" options={{ label: 'Communities' }} list={communitiesList} />,
        // ) : null,

        // SETTINGS
        permissions.roles.includes('corp') ? (
          <Resource name="settings" options={{ label: 'Settings' }} {...settings} />
        ) : null,

        // MORTGAGES
        permissions.roles.includes('corp') ? (
          <Resource name="mortage" options={{ label: 'Mortgages' }} {...mortage} />
        ) : null,

        // Conversations
        permissions.roles.includes('corp') ||
        permissions.roles.includes('office') ||
        permissions.roles.includes('rep') ? (
          <Resource name="conversations" options={{ label: 'Conversations' }} {...conversations} />
        ) : null,

        //Admin Documnet Homes
        permissions.roles.includes('corp') || permissions.roles.includes('office') ? (
          <Resource
            name="adminDocuments/homes"
            options={{ label: 'Home Documents' }}
            list={DocumentHomesList}
            create={DocumentHomesCreate}
          />
        ) : null,

        //Admin document Communities
        permissions.roles.includes('corp') || permissions.roles.includes('office') ? (
          <Resource
            name="adminDocuments/communities"
            options={{ label: 'Community Documents' }}
            list={DocumentCommunitiesList}
            create={DocumentCommunitiesCreate}
          />
        ) : null,

        // Admin Docuent Divisions
        permissions.roles.includes('corp') || permissions.roles.includes('office') ? (
          <Resource
            name="adminDocuments/divisions"
            options={{ label: 'Division Documents' }}
            list={DocumentDivisionsList}
            create={DocumentDivisionsCreate}
          />
        ) : null,

        // <Resource
        //   name="adminAnnouncements/divisions"
        //   list={AnnouncementDivisionsList}
        //   create={AnnouncementDivisionsCreate}
        // />,
        // <Resource name="adminAnnouncements/communities" list={ListGuesser} />,
      ]}
    </Admin>
  );
};

export default App;
