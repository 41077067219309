import * as React from 'react';
import { Datagrid, TextField, ImageField, List } from 'react-admin';

import PopUp, { ButtonField } from './PopUp';
export const HomesList = (props) => {
  const [openPopUp, setOpenPopup] = React.useState(false);
  const [record, setRecord] = React.useState();
  return (
    <div>
      <List {...props} sort={{ field: 'name', order: 'ASC' }} bulkActionButtons={false}>
        <Datagrid>
          <TextField source="id" sortable={false} />
          <TextField source="name" label="Name" />
          <TextField source="zip" label="Zip" />
          <TextField source="elevation" label="Elevation" />
          <ImageField source="elevationURL[0]" label="Elevation URL" sortable={false} />
          <TextField source="community.name" label="Community Name" />
          <TextField source="division.name" label="Division Name" />
          <TextField source="address" label="Address" />
          <ButtonField
            source="show buyers"
            setRecord={setRecord}
            setOpenPopup={setOpenPopup}
            sortable={false}
          >
            Show buyers
          </ButtonField>
          {/* <ArrayField source="homebuyers">
          <Datagrid>
            <TextField source="firstName" />
            <TextField source="lastName" />
            <ChipField source="email" />
          </Datagrid>
        </ArrayField> */}
        </Datagrid>
      </List>
      {openPopUp ? (
        <PopUp openPopUp={openPopUp} setOpenPopup={setOpenPopup} record={record} />
      ) : null}
    </div>
  );
};
