import * as React from 'react';
import { Datagrid, List, TextField, FunctionField, Button, SaveButton } from 'react-admin';
import { Typography, TextField as MyTextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { BASE_URL } from '../../Constants';

const Aside = ({ record, asideState }) => {
  const { id } = record;
  const { setShowMessages } = asideState;
  // const { data, loading, error } = useGetOne('conversations', id);
  // console.log(data, loading, error);
  const [messages, setMessages] = React.useState([]);
  const [isError, setIsError] = React.useState(false);

  React.useEffect(() => {
    const getMessages = async (id) => {
      const token = JSON.parse(localStorage.getItem('token'));
      const msgs = await fetch(`${BASE_URL}/conversations/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token.token}`,
        },
      })
        .then((data) => data.json())
        .catch(() => {
          setIsError(true);
        });
      setMessages(msgs.messages);
    };
    getMessages(id);
  }, [id]);

  return (
    <div
      style={{
        background: '#f2f2f2',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: 350,
        padding: '1em',
        margin: '0 10px',
        height: 'calc(100vh - 180px)',
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      }}
    >
      <CancelIcon
        style={{ position: 'absolute', top: '-10px', right: '-10px', cursor: 'pointer' }}
        onClick={() => {
          setShowMessages(false);
        }}
      />
      <Typography variant="h5">Messages</Typography>
      <Typography variant="h6" style={{ fontSize: '13px' }}>
        {`${record.buyerData.firstName} ${record.buyerData.lastName} - ${record.adminData.firstName} ${record.adminData.lastName}`}
      </Typography>
      <div style={{ overflow: 'auto', marginBottom: '20px' }}>
        {isError ? (
          <Typography variant="body2">Something went wrong. Please try again</Typography>
        ) : null}

        {messages && messages.length > 0 ? (
          messages.map((msg) => {
            // const datetime = new Date(msg.dateTime._seconds * 1000);
            return (
              <>
                {msg.from === 'admin' ? (
                  <div
                    style={{
                      background: '#fff',
                      float: 'right',
                      width: '70%',
                      padding: '12px',
                      borderRadius: '20px 2px',
                      marginTop: '10px',
                    }}
                  >
                    {msg.message}
                  </div>
                ) : (
                  <div
                    style={{
                      background: '#fff',
                      float: 'left',
                      width: '70%',
                      padding: '12px',
                      borderRadius: '20px 2px',
                      marginTop: '10px',
                    }}
                  >
                    {msg.message}
                  </div>
                )}
              </>
            );
          })
        ) : (
          <>
            <Typography variant="h6">No messages yet. Start chat?</Typography>
          </>
        )}
      </div>
      {record.isAdminAllowedToMessage ? (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 96px', marginTop: 'auto' }}>
          <MyTextField id="standard-basic" label="Message" />
          <SaveButton label="Send" />
        </div>
      ) : null}
    </div>
  );
};

export const ConversationList = ({ permissions, ...props }) => {
  const [showMessages, setShowMessages] = React.useState(false);
  const [rowRecord, setRowRecord] = React.useState({});
  return (
    <div>
      <List
        {...props}
        sort={{ field: 'lastMessageDateTime', order: 'asc' }}
        bulkActionButtons={false}
        aside={
          showMessages ? (
            <Aside record={rowRecord} asideState={{ showMessages, setShowMessages }} />
          ) : null
        }
      >
        {/* <SimpleList
          {...props}
          primaryText={(record) =>
            `Buyer: ${record.buyerData.firstName} ${record.buyerData.lastName}`
          }
          secondaryText={(record) =>
            `Admin: ${record.adminData.firstName} ${record.adminData.lastName} (${record.adminData.designation})`
          }
          tertiaryText={(record) => `Unread Messages: ${record.adminData.unreadMessages}`}
          onClick={(e) => handleRowClick(props)}
        ></SimpleList> */}
        <div style={{ alignItems: 'flex-start' }}>
          <Datagrid>
            <TextField source="id" sortable={false} />
            <TextField source="adminData.designation" label="Admin Designation" sortable={false} />
            <FunctionField
              label="Admin"
              render={(record) => {
                return `${record.adminData.firstName} ${record.adminData.lastName}`;
              }}
            />
            <FunctionField
              label="Buyer"
              render={(record) => {
                return `${record.buyerData.firstName} ${record.buyerData.lastName}`;
              }}
            />
            <TextField label="Unreads" source="adminData.unreadMessages" sortable={false} />
            <ButtonField
              label="Message"
              setRecord={setRowRecord}
              setShowAside={setShowMessages}
              sortable={false}
            />
          </Datagrid>
        </div>
      </List>
    </div>
  );
};

export function ButtonField(props) {
  const { record, setRecord, setShowAside } = props;
  return (
    <Button
      label={'Show Messages'}
      onClick={() => {
        setRecord(record);
        setShowAside(true);
      }}
    />
  );
}
