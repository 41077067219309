import * as React from 'react';
import { List, Datagrid, TextField, } from 'react-admin';

export const communitiesList = (props) => {
  return (
    <List {...props} sort={{ field: 'name', order: 'ASC' }} bulkActionButtons={false} perPage={5}>
      <Datagrid rowClick="edit">
        <TextField source="id" sortable={false} />
        <TextField source="name" />
        <TextField source="divisionData.name" label="Division Name" sortable={false} />
      </Datagrid>
    </List>
  );
};
