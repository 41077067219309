import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  required,
  AutocompleteArrayInput,
  BooleanInput,
  ReferenceArrayInput,
  FormDataConsumer,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { ADMIN_ROLES } from '../../Constants/index';

const WithProps = ({ children, ...props }) => children(props);

const WriteToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.changed} redirect="list" />
    </Toolbar>
  );
};

export const AdminEdit = ({ record, ...props }) => {
  const [changed, setChanged] = React.useState(true);
  return (
    <Edit {...props}>
      <WithProps>
        {({ record, ...props }) => (
          <SimpleForm record={record} {...props} toolbar={<WriteToolbar changed={changed} />}>
            <TextField source="Edit Admin" />
            <TextInput source="id" disabled />
            <TextInput
              onChange={() => {
                setChanged(false);
              }}
              disabled={record.inviteStatus === true}
              source="email"
              validate={[required()]}
            />
            <TextInput
              source="firstName"
              onChange={() => {
                setChanged(false);
              }}
              validate={[required()]}
            />
            <TextInput
              source="lastName"
              onChange={() => {
                setChanged(false);
              }}
              validate={[required()]}
            />

            <TextInput
              source="designation"
              onChange={() => {
                setChanged(false);
              }}
              validate={[required()]}
            />

            <AutocompleteArrayInput validate={[required()]} source="roles" choices={ADMIN_ROLES} />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (formData.roles && formData.roles.includes('corp')) {
                  formData.divisions = [];
                  formData.communities = [];
                  return null;
                } else if (
                  formData.roles &&
                  formData.roles.includes('rep') &&
                  formData.roles.includes('office')
                ) {
                  return (
                    <React.Fragment>
                      <ReferenceArrayInput
                        validate={[required()]}
                        source="communities"
                        reference="communities"
                        sort={{ field: 'name', order: 'ASC' }}
                        onChange={() => {
                          setChanged(false);
                        }}
                      >
                        <AutocompleteArrayInput optionText="name" />
                      </ReferenceArrayInput>
                      <ReferenceArrayInput
                        onChange={() => {
                          setChanged(false);
                        }}
                        validate={[required()]}
                        source="divisions"
                        reference="divisions"
                        sort={{ field: 'name', order: 'ASC' }}
                      >
                        <AutocompleteArrayInput optionText="name" />
                      </ReferenceArrayInput>
                    </React.Fragment>
                  );
                } else if (formData.roles && formData.roles.includes('rep')) {
                  formData.divisions = [];
                  return (
                    <ReferenceArrayInput
                      onChange={() => {
                        setChanged(false);
                      }}
                      validate={[required()]}
                      source="communities"
                      reference="communities"
                      sort={{ field: 'name', order: 'ASC' }}
                    >
                      <AutocompleteArrayInput optionText="name" />
                    </ReferenceArrayInput>
                  );
                } else if (formData.roles && formData.roles.includes('office')) {
                  formData.communities = [];
                  return (
                    <ReferenceArrayInput
                      onChange={() => {
                        setChanged(false);
                      }}
                      validate={[required()]}
                      source="divisions"
                      reference="divisions"
                      sort={{ field: 'name', order: 'ASC' }}
                    >
                      <AutocompleteArrayInput optionText="name" />
                    </ReferenceArrayInput>
                  );
                }
              }}
            </FormDataConsumer>

            <BooleanInput
              onChange={() => {
                setChanged(false);
              }}
              label="Allow Conversation"
              source="conversationFlag"
              defaultValue={true}
            />
            <BooleanInput
              onChange={() => {
                setChanged(false);
              }}
              disabled={record.inviteStatus === false}
              label="Status"
              source="status"
              defaultValue={false}
            />
            <BooleanInput
              label="Invite Status"
              source="inviteStatus"
              defaultValue={false}
              disabled
            />
          </SimpleForm>
        )}
      </WithProps>
    </Edit>
  );
};
