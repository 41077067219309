import * as React from 'react';
import { Fragment } from 'react';
import { List, MenuItem, ListItemIcon, Typography, Collapse, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useTranslate, } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  icon: { minWidth: theme.spacing(5) },
  sidebarIsOpen: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(4),
    },
  },
  sidebarIsClosed: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(2),
    },
  },
}));

const SubMenu = (props) => {
  const { name, children} = props;
  const translate = useTranslate();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const header = (
    <MenuItem button onClick={() => setOpen(!open)}>
      <ListItemIcon className={classes.icon}>
        <ExpandMore />
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {translate(name)}
      </Typography>
    </MenuItem>
  );
  return (
    <Fragment>
      <Tooltip title={translate(name)} placement="right">
        {header}
      </Tooltip>
      <Collapse in={open}>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
