import * as React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import { WriteToolbar } from '../../Components/WriteToolBar';

export const SettingEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<WriteToolbar />}>
      <TextInput disabled label="id" source="id" />
      <TextInput disabled label="field" source="field" />
      <TextInput source="value" validate={required()} />
    </SimpleForm>
  </Edit>
);
