import * as React from 'react';
import { Datagrid, TextField, EditButton, FunctionField, BooleanField, List } from 'react-admin';
import { ADMIN_ROLES } from '../../Constants/index';

export const AdminsList = ({ permissions, ...props }) => {
  return (
    <div>
      <List {...props} sort={{ field: 'email', order: 'ASC' }} bulkActionButtons={false}>
        <Datagrid>
          <TextField source="id" sortable={false} />
          <TextField source="email" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <FunctionField
            label="Roles"
            render={(record) => {
              const rolesList = record.roles.map((role) => {
                const roleFind = ADMIN_ROLES.find((adminRole) => adminRole.id === role);
                if (roleFind) return roleFind.name;
                return 'Super Admin';
              });
              return rolesList.join([', ']);
            }}
          />
          <FunctionField
            label="Divisions"
            render={(record) => {
              const divisionsName = record.divisions.map((division) => division.name);
              return divisionsName.join([', ']);
            }}
          />
          <FunctionField
            label="Communities"
            render={(record) => {
              const communitiesName = record.communities.map((community) => community.name);
              return communitiesName.join([', ']);
            }}
          />
          <BooleanField source="conversationFlag" label="Allow Conversation" />
          {permissions && permissions.roles.includes('super') ? (
            <EditButton basePath="/admins" label="Edit Admin" />
          ) : null}
        </Datagrid>
      </List>
    </div>
  );
};
