import * as React from 'react';
import { Datagrid, TextField, List, EditButton } from 'react-admin';

export const SettingList = (props) => {
  return (
    <List {...props} sort={{ field: 'field', order: 'ASC' }} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="field" />
        <TextField source="value" />
        <EditButton basePath="/settings" label="Edit" />
      </Datagrid>
    </List>
  );
};
