import {
  Typography,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-admin';
import { BASE_URL } from '../../Constants';
import { getUser } from '../../Providers/index.js';
export default function PopUp(props) {
  const { openPopUp, setOpenPopup, record } = props;
  const [data, setData] = useState();
  const [error, setError] = useState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setError('');
    setData('');
    // const token = localStorage.getItem('token');
    async function getCommunities() {
      const res = await getUser();
      const tokenId = res.Aa;
      const token = tokenId;
      const data = await fetch(`${BASE_URL}/divisionCommunities?divisionId=${record.id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    }
    const sample = await getCommunities();
    const status = sample.status;
    if (status === 200) {
      const data = await sample.json();
      if (data.length < 1) setError('No records found');
      setData(data);
    } else {
      setError('Internal server error');
    }
  }, [record.id]);

  return (
    <Dialog open={openPopUp}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        Division Name - {record.name}
      </DialogTitle>
      <DialogContent>
        <List>
          {data
            ? data.map((community) => {
                return (
                  <React.Fragment key={community.id}>
                    <ListItem style={{ display: 'block' }}>
                      <Typography component="div" style={{ margin: '2px 0' }}>
                        <Typography component="span" style={{ fontWeight: 600 }}>
                          Community Id :
                        </Typography>{' '}
                        {community.id}
                      </Typography>
                      <Typography component="div" style={{ margin: '2px 0' }}>
                        <Typography component="span" style={{ fontWeight: 600 }}>
                          Community Name :
                        </Typography>{' '}
                        {community.name}
                      </Typography>
                      <Divider component="div" style={{ marginTop: '10px' }} />
                    </ListItem>
                  </React.Fragment>
                );
              })
            : null}
          {error ? <div>{error}</div> : null}
        </List>
      </DialogContent>
      <DialogActions style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <Button
          label="close"
          onClick={() => {
            console.log('closings');
            setOpenPopup(false);
          }}
        ></Button>
      </DialogActions>
    </Dialog>
  );
}

export function ButtonField(props) {
  const { record, setRecord, setOpenPopup } = props;
  return (
    <Button
      label="View Communities"
      onClick={() => {
        setRecord(record);
        setOpenPopup(true);
      }}
    />
  );
}
