import * as React from 'react';
import { Menu, MenuItemLink, getResources, useTranslate, usePermissions } from 'react-admin';
import { useSelector } from 'react-redux';
import DefaultIcon from '@material-ui/icons/ViewList';
import SubMenu from './subMenu';
export const CustomMenu = (props) => {
  const resources = useSelector(getResources);
  const permissions = usePermissions();
  const role = permissions.loading === false ? permissions.permissions.roles[0] : '';
  const translate = useTranslate();
  return (
    <>
      {permissions.loading === false ? (
        <Menu {...props}>
          {/* <DashboardMenuItem /> */}
          {resources.map((resource) => {
            return resource.name !== 'adminDocuments/communities' &&
              resource.name !== 'adminDocuments/homes' &&
              resource.name !== 'adminDocuments/divisions' ? (
              role === 'office' && resource.name === 'communities' ? null : (
                <MenuItemLink
                  key={resource.name}
                  to={`/${resource.name}`}
                  primaryText={(resource.options && resource.options.label) || resource.name}
                  leftIcon={<DefaultIcon />}
                />
              )
            ) : null;
          })}
          {role === 'office' || role === 'corp' ? (
            <SubMenu name="Documents">
              <MenuItemLink
                to={{
                  pathname: '/adminDocuments/homes',
                }}
                primaryText={translate(`Home Documents`, {
                  smart_count: 2,
                })}
                leftIcon={<DefaultIcon />}
              />
              <MenuItemLink
                to={{
                  pathname: '/adminDocuments/divisions',
                }}
                primaryText={translate(`Division Documents`, {
                  smart_count: 2,
                })}
                leftIcon={<DefaultIcon />}
              />
              <MenuItemLink
                to={{
                  pathname: '/adminDocuments/communities',
                }}
                primaryText={translate(`Community Documents`, {
                  smart_count: 2,
                })}
                leftIcon={<DefaultIcon />}
              />
            </SubMenu>
          ) : null}
          {/* <MenuItemLink to="/hello" primaryText="hello" leftIcon={<DefaultIcon />} /> */}
        </Menu>
      ) : null}
    </>
  );
};
