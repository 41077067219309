import * as React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import PopUp, { ButtonField } from './PopUp';

export const DivisionList = (props) => {
  const [openPopUp, setOpenPopup] = React.useState(false);
  const [record, setRecord] = React.useState();

  return (
    <div>
      <List {...props} sort={{ field: 'name', order: 'ASC' }} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
          <TextField source="id" sortable={false} />
          <TextField source="name" />
          <ButtonField
            sortable={false}
            source="id"
            label="Communities"
            setRecord={setRecord}
            setOpenPopup={setOpenPopup}
          >
            Show communities
          </ButtonField>
        </Datagrid>
      </List>
      {openPopUp ? (
        <PopUp openPopUp={openPopUp} setOpenPopup={setOpenPopup} record={record} />
      ) : null}
    </div>
  );
};
