import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  TextField,
  required,
  email,
  AutocompleteArrayInput,
  BooleanInput,
  ReferenceArrayInput,
  FormDataConsumer,
} from 'react-admin';
import { ADMIN_ROLES } from '../../Constants/index';
import { WriteToolbar } from '../../Components/WriteToolBar';
export const AdminCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm toolbar={<WriteToolbar />}>
        <TextField source="Create Admin" />
        <TextInput label="Email Address" source="email" validate={[required(), email()]} />
        <TextInput source="firstName" validate={[required()]} />
        <TextInput source="lastName" validate={[required()]} />
        <TextInput source="designation" validate={[required()]} />
        <AutocompleteArrayInput validate={[required()]} source="roles" choices={ADMIN_ROLES} />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.roles && formData.roles.includes('corp')) {
              formData.divisions = [];
              formData.communities = [];
              return null;
            } else if (
              formData.roles &&
              formData.roles.includes('rep') &&
              formData.roles.includes('office')
            ) {
              return (
                <React.Fragment>
                  <ReferenceArrayInput
                    validate={[required()]}
                    source="communities"
                    reference="communities"
                    sort={{ field: 'name', order: 'ASC' }}
                  >
                    <AutocompleteArrayInput optionText="name" />
                  </ReferenceArrayInput>
                  <ReferenceArrayInput
                    validate={[required()]}
                    source="divisions"
                    reference="divisions"
                    sort={{ field: 'name', order: 'ASC' }}
                  >
                    <AutocompleteArrayInput optionText="name" />
                  </ReferenceArrayInput>
                </React.Fragment>
              );
            } else if (formData.roles && formData.roles.includes('rep')) {
              formData.divisions = [];
              return (
                <ReferenceArrayInput
                  validate={[required()]}
                  source="communities"
                  reference="communities"
                  sort={{ field: 'name', order: 'ASC' }}
                >
                  <AutocompleteArrayInput optionText="name" />
                </ReferenceArrayInput>
              );
            } else if (formData.roles && formData.roles.includes('office')) {
              formData.communities = [];
              return (
                <ReferenceArrayInput
                  validate={[required()]}
                  source="divisions"
                  reference="divisions"
                  sort={{ field: 'name', order: 'ASC' }}
                >
                  <AutocompleteArrayInput optionText="name" />
                </ReferenceArrayInput>
              );
            }
          }}
        </FormDataConsumer>
        <BooleanInput label="Allow Conversation" source="conversationFlag" defaultValue={true} />
        {/*
          <BooleanInput label="Status" source="status" defaultValue={false} disabled />
          <BooleanInput label="Invite Status" source="status" defaultValue={false} disabled />
        */}
      </SimpleForm>
    </Create>
  );
};
