// import * as admin from 'firebase-admin';
import firebase from 'firebase';

export const firebaseConfig = {
  apiKey: 'AIzaSyDcBcL7BrZxwBu2J-cxBLBChWxhhgxlSwU',
  authDomain: 'testwrh-cms.firebaseapp.com',
  projectId: 'testwrh-cms',
  storageBucket: 'testwrh-cms.appspot.com',
  messagingSenderId: '354257854527',
  appId: '1:354257854527:web:30569a8288a6ea3dbb188a',
  measurementId: 'G-C8ZVBEX2QT',
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export default firebase;
