export const BASE_URL = 'https://us-central1-testwrh-cms.cloudfunctions.net';
// export const BASE_URL = 'http://localhost:5001/testwrh-cms/us-central1';

// const BASE_URL = 'https://jsonplaceholder.typicode.com';

// export const ADMIN_ROLES = {
//   rep: 'Sales Representative',
//   office: 'Office Admin',
//   corp: 'Corporate Admin',
// };

export const ADMIN_ROLES = [
  { id: 'rep', name: 'Sales Representative' },
  { id: 'office', name: 'Office Admin' },
  { id: 'corp', name: 'Corporate Admin' },
];

export const TYPE = [
  { id: 'home', name: 'home' },
  { id: 'community', name: 'community' },
  { id: 'division', name: 'division' },
];
