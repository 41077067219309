import * as React from 'react';
import { Datagrid, TextField, ImageField, List } from 'react-admin';
export const HomeBuyersList = (props) => {
  return (
    <List {...props} sort={{ field: 'firstName', order: 'ASC' }} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <TextField source="phoneNumber" />
        <ImageField source={"photoURL"} label="Photo" sortable={false} />
      </Datagrid>
    </List>
  );
};
