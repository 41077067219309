import * as React from 'react';
import { Datagrid, TextField, ImageField, List, UrlField, EditButton } from 'react-admin';

export const MortagesList = (props) => {
  return (
    <List {...props} sort={{ field: 'link', order: 'ASC' }} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <UrlField source="link" label="Link" />
        <ImageField source="logo[0]" label="Logo" title="logo" sortable={false} />
        <EditButton basePath="/mortage" label="Edit" />
      </Datagrid>
    </List>
  );
};
